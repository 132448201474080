const api_functions = {

	get_api_root_url : function () { 
	    let apiurl = process.env.REACT_APP_API_URL;
	    if (apiurl == null || apiurl === 'undefined' || apiurl === '')
	    {
		apiurl = 'https://wordthird.com/api';
	    }
	    console.log('api_functions() Returning ' + apiurl);
	    return apiurl;
		//return 'http://0.wordspeedgame.a.vlan17.client.tagonline.com/api'; 
		//return 'http://0.wordspeedgame.a.17.san.int.tagonline.com/api'; 
		//return 'http://192.168.7.201/api'; 
		//return 'http://localhost:5000/api';
	},
	get_register_new_user_ep: function () {
		return api_functions.get_api_root_url() + '/0/register_new_user/';
	},
	get_log_in_ep: function () {
		return api_functions.get_api_root_url() + '/0/log_in/';
	},
	get_create_game_ep: function () {
		return api_functions.get_api_root_url() + '/0/create_game/';
	},
	get_get_todays_game_ep: function () {
		return api_functions.get_api_root_url() + '/0/get_todays_game/';
	},
	get_get_game_from_date_ep: function () { 
		return api_functions.get_api_root_url() + '/0/get_game_from_date/'; 
	},
	get_test_ep: function () {
		return api_functions.get_api_root_url() + '/test/0/';
	},

	get_todays_game_v0: function () {
		let todays_game = { 
			theme: "pets", 
			magic_letter: "b", 
			words: ["cat", "food", "birds"]
		}
		return todays_game;
	},
	get_todays_game_v1: async function () {
		let json_request_as_obj = {
			player_id: 		api_functions.get_cookie_by_name('player_id'), 
			device_or_user_id: 	api_functions.get_cookie_by_name('device_or_user_id'),
			session_id: 		api_functions.get_cookie_by_name('session_id')
		}		
		
		let response_promise_json = fetch( api_functions.get_get_todays_game_ep(), 
		{
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(json_request_as_obj)
			//credentials: 'include'
		})
		.then( res => res.json() );
		return response_promise_json;	
	},

	get_cookie: function() {
		return document.cookie;
	},
	get_cookie_by_name: function(name){
		let nameEQ = name + "=";
		let ca = document.cookie.split(';');
		let target_cookie = null;
		for(let i=0;i < ca.length;i++) {
		    	let c = ca[i];
			while (c.charAt(0)==' ') {
				c = c.substring(1,c.length);
			}
		    	if (c.indexOf(nameEQ) == 0){
				target_cookie = c.substring(nameEQ.length,c.length);
			}
		}
		return target_cookie;
	},	
	create_cookie_expiration_date: function(days) {
		let expires ="";
		if (days) {
			let date = new Date();		
			date.setTime(date.getTime() + (days*24*60*60*1000)); //2 weeks		
			expires = "; expires=" + date.toUTCString();
		}
		return expires;
	},
	set_cookie: function(name, value, days) { 
		let expires = api_functions.create_cookie_expiration_date(days);
		document.cookie = name + "=" + (value || "")  + expires + "; path=/";
	},
	set_game_cookies: function (json) {
		let days=14;
		if (api_functions.get_cookie_by_name('player_id') === null){
			api_functions.set_cookie('player_id', json.player_id, days);
			//console.log('set player id cookie');
		}
		if (api_functions.get_cookie_by_name('device_or_user_id') === null){
			api_functions.set_cookie('device_or_user_id', json.device_or_user_id, days); 
			//console.log('set device or user id cookie');
		}
		if (api_functions.get_cookie_by_name('session_id') === null){	
			api_functions.set_cookie('session_id', json.session_id, days);
			//console.log('set session id cookie');
		}
	},

}

export default api_functions;
