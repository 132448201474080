import styled from "styled-components";

import { MOBILE_BREAKPOINT, MOBILE_SMALLER_BREAKPOINT } from "../../constants";

const StyledKey = styled.button `
    width: 37px;
    height: 45px;
    text-transform: uppercase;
    cursor: pointer;
    background-color: #1f1f1f;
    color: #fff;
    border: none;
    border-radius: 6px;
    font-size: 16px;
    padding: 5px 6px 5px;
    margin: 1.5px 1px 0;
    transition: all 0.12s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    &:hover {
        transform: scale(1.02);
        opacity: 0.9;
    }
    &:active {
        transform: translateY(1px);
    }
    @media screen and (max-width: ${MOBILE_BREAKPOINT}){
        width: 38px;
        height: 55px;
        font-size: 18px;
        padding: 3px;
    }
    @media screen and (max-width: ${MOBILE_SMALLER_BREAKPOINT}){
        width: unset;
        flex: 1;
        &.enter {
            flex: 2;
        }
    }
`;

function Key(props) {
    let letter = props['letter'];
    let backspace = props['backspace'];
    let enter = props['enter'];
    //Renders letters into game board boxes when on-screen keyboard key is clicked
    if(backspace === 'backspace'){
        return <StyledKey className="backspace" onClick={props.handleClick[1]} ><img src="/assets/img/backspace.svg" /></StyledKey> 
    } else if(enter === 'enter') {
        return <StyledKey className="enter" onClick={props.handleClick[2]} >Enter</StyledKey> 
    } else {
	if ( props.keyboardColors.lettersInTargetWordCorrectSpot.includes(props.letter) ) {
        	return <StyledKey className="correct-letter-correct-spot-color" onClick={props.handleClick[0]} data-letter={letter}>{letter}</StyledKey> ;
	}
	if ( props.keyboardColors.lettersInTargetWordWrongSpot.includes(props.letter) ) {
        	return <StyledKey className="correct-letter-wrong-spot-color" onClick={props.handleClick[0]} data-letter={letter}>{letter}</StyledKey> ;
	}
	if ( props.keyboardColors.lettersNotInTargetWord.includes(props.letter) ) {
        	return <StyledKey className="wrong-letter-color" onClick={props.handleClick[0]} data-letter={letter}>{letter}</StyledKey> ;
	}
        return <StyledKey onClick={props.handleClick[0]} data-letter={letter}>{letter}</StyledKey> ;
    }
    
}


export default Key;
